import Breadcrumbs from '@components/common/Breadcrumbs';
import AdminCreateReportComponent from './AdminCreateReportComponent';
import { useLocation } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { getSessionUserFromAccessToken } from '@utils/utils';

const AdminCreateReportPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const reportType = queryParams.get('route');

  const breadcrumbsLabel = reportType === 'reports' ? 'Reports' : 'Monikers(Reported)';
  const [currentUser, setCurrentUser] = useState<any>({});

  useEffect(() => {
    const getSessionUser = async () => {
      let user;

      user = await getSessionUserFromAccessToken();

      setCurrentUser(user);
    };
    getSessionUser();
    console.log(currentUser);
  }, []);

  const breadcrumbsLink =
    reportType === 'reports' ? '/admin/reports' : '/admin/portfolios/monikers';
  const formMethods = useForm({
    values: {
      reportNumber: '',
      scamEnablers: {
        Banks: [{ rows: [{}, {}] }],
        Paynow: [{ rows: [{}, {}] }],
        Monikers: [{}],
        Fintech: [{ rows: [{}, {}] }],
      },
      monetaryLossInvolved: 'false',
      policeStation: '',
      spfEmail: currentUser.email,
    },
    mode: 'onChange',
  });

  return (
    <>
      <div className="w-100 bg-grey-200 p-4 flex flex-row justify-between items-center">
        <Breadcrumbs
          breadcrumbs={[
            { label: breadcrumbsLabel, href: breadcrumbsLink },
            {
              label: `Add Report - Manual Entry`,
              href: `/admin/reports/create`,
              active: true,
            },
          ]}
        />
      </div>

      <div className="flex flex-col h-full items-center justify-center min-h-screen bg-white">
        <div className="flex flex-col w-full h-full gap-2 bg-white  rounded-lg ">
          <FormProvider {...formMethods}>
            <AdminCreateReportComponent formMethods={formMethods} currentUser={currentUser} />
          </FormProvider>
        </div>
      </div>
    </>
  );
};

export default AdminCreateReportPage;
