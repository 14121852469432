import { ochaDirections } from '@interface/constant';
import { FormItemInterface } from '@interface/dynamicFormInterface';

export const checkboxItemsInternalWorkProcesses = [
  {
    id: 'checklist1_internalWorkProcesses_Q1',
    name: 'Is this a fresh submission? (There is no past submission sent against the same enabler(s) in CA Masterlist)',
    tableFieldType: 'checkbox',
  },
  {
    id: 'checklist1_internalWorkProcesses_Q2',
    name: 'Is there a need for escalation of direction*?',
    subText:
      '* For escalation of direction, to fill up Part A – pre-cleared criteria for OCHA Channel',
    dependency: {
      id: 'checklist1_internalWorkProcesses_Q1',
      value: false,
      label: 'Only applicable if Q1 is a No',
    },
    tableFieldType: 'checkbox',
  },
  {
    id: 'checklist1_internalWorkProcesses_Q3',
    name: 'Does the screenshot match the enabler(s) (i.e. user ID/number) submitted?',
    subText: '*If yes, continue to fill up Part A – pre-cleared criteria for OCHA Channel',
    tableFieldType: 'checkbox',
  },
  {
    id: 'checklist1_internalWorkProcesses_Q4',
    name: 'Is the scam page live?',
    tableFieldType: 'checkbox',
  },
  {
    id: 'checklist1_internalWorkProcesses_Q5',
    name: 'What is the social media account opening date?',
    tableFieldType: 'date',
  },
  {
    id: 'checklist1_internalWorkProcesses_Q6',
    name: 'Any change in the online name/moniker of the social media account?',
    tableFieldType: 'checkbox',
  },
  {
    id: 'checklist1_internalWorkProcesses_Q7',
    name: 'Is the social media account/number traced to other reports?',
    tableFieldType: 'checkbox',
  },
];

export const checkboxItemsOnlineMaterial = [
  {
    id: 'checklist1_onlineMaterial_Q1',
    name: 'Suspect or has reason to believe that any online activity is preparatory to, the commission of a scam; or',
    tableFieldType: 'checkbox',
  },
  {
    id: 'checklist1_onlineMaterial_Q2',
    name: 'Suspect or has reason to believe that any online activity is in furtherance of, the commission of a scam.',
    tableFieldType: 'checkbox',
  },
];

export const checkboxItemsScamChecklist = [
  {
    id: 'checklist1_scamChecklist_Q1',
    name: 'Reasonable suspicion that the offence has a nexus with Singapore i.e. occurred in Singapore, targeting victims in Singapore, etc',
    tableFieldType: 'checkbox',
  },
  {
    id: 'checklist1_scamChecklist_Q2',
    name: 'Any other parameter(s) which may further define ‘scams’ as decided by ASC.',
    tableFieldType: 'checkbox',
  },
];

export const checkboxItemsEvaluation = [
  {
    id: 'checklist1_evaluation_Q1',
    name: 'Is the online material a Mobile Application?',
    tableFieldType: 'checkbox',
  },
  {
    id: 'checklist1_evaluation_Q1A',
    name: 'Application does not belong to any legitimate entity.',
    dependency: {
      id: 'checklist1_evaluation_Q1',
      value: true,
      label: 'Only applicable if Q1 is a Yes',
    },
    tableFieldType: 'checkbox',
  },
  {
    id: 'checklist1_evaluation_Q1B',
    name: 'Removal of application will not affect legitimate entities.',
    dependency: {
      id: 'checklist1_evaluation_Q1',
      value: true,
      label: 'Only applicable if Q1 is a Yes',
    },
    tableFieldType: 'checkbox',
  },
  {
    id: 'checklist1_evaluation_Q2',
    name: 'Justification',
    tableFieldType: 'textInput',
    extras: {
      rows: 4,
    },
  },
  {
    id: 'direction',
    name: 'What is the type of direction to issue?',
    options: ochaDirections,
    placeholder: 'Please select',
    tableFieldType: 'dropdown',
    rules: {
      required: 'Please select an option',
    },
  },
];

export const checkboxItemsLegalRequirements = [
  {
    id: 'checklist2_legalRequirements_Q1',
    name: 'Direction request meets the requirements under Sec 6(1)(b) OCHA?',
    tableFieldType: 'checkbox',
  },
];

export const checkboxItemsOperationalRequirements = [
  {
    id: 'checklist2_operationalRequirements_Q1',
    name: 'Is the online scam enabler(s)* populated correctly?',
    tableFieldType: 'checkbox',
  },
  {
    id: 'checklist2_operationalRequirements_Q2',
    name: 'Is the identified Direction appropriate to block the online scam enabler?',
    tableFieldType: 'checkbox',
  },
  {
    id: 'checklist2_operationalRequirements_Q3',
    name: '[For Mobile Application] Check has been done by DA to ensure legitimate entity is not affected by the blocking.',
    tableFieldType: 'checkbox',
  },
];

export const checkboxItemsChecks = [
  {
    id: 'checklist2_checks_Q1',
    name: 'Is this a fresh submission? (There is no previous Direction issued against the same enabler(s) in CA master list)',
    tableFieldType: 'checkbox',
  },
];

export const getCheckboxItemsSubstituteOrder = (
  handleReferenceNoChange?: any,
  isReferenceNoValid?: boolean | null,
  isValidating?: boolean,
): any => {
  return [
    {
      id: 'substitutionOrder',
      name: 'Do you want to send this as a substitution order?',
      tableFieldType: 'checkbox',
    },
    {
      id: 'substitutionOrderReferenceNo',
      name: 'Please indicate the OCHA direction reference no. of the direction that was previously sent.',
      dependency: {
        id: 'substitutionOrder',
        value: true,
      },
      tableFieldType: 'textInput',
      extras: {
        onChange: handleReferenceNoChange,
        isValid: isReferenceNoValid,
        isValidating: isValidating,
      }
    },
  ];
};

export const assessmentFindingsInitialItems: Array<FormItemInterface> = [
  {
    label: 'Internal Work Processes',
    type: 'tableChecklist',
    id: 'internalWorkProcesses',
    extras: { checkboxData: checkboxItemsInternalWorkProcesses },
    alignment: 'column',
  },
];

export const assessmentFindingsDa1PartItemsV100: Array<FormItemInterface> = [
  {
    label: '(A) Online Material / Location / Account',
    type: 'tableChecklist',
    id: 'onlineMaterial',
    extras: {
      checkboxData: checkboxItemsOnlineMaterial,
      tooltip: {
        content: 'onlineMaterial',
      },
    },
    alignment: 'column',
    rules: {
      minimum: 1,
    },
  },
  {
    label: '(B) Scam Checklist',
    type: 'tableChecklist',
    id: 'scamChecklist',
    extras: {
      checkboxData: checkboxItemsScamChecklist,
      tooltip: {
        content: 'scamChecklist',
      },
    },
    alignment: 'column',
    rules: {
      minimum: 1,
    },
  },
  {
    label: '(C) Evaluation',
    type: 'tableChecklist',
    id: 'evaluation',
    extras: {
      checkboxData: checkboxItemsEvaluation,
    },
    alignment: 'column',
  },
];

export const assessmentFindingsDa2PartItemsV100: Array<FormItemInterface> = [
  {
    label: 'Legal Requirements',
    type: 'tableChecklist',
    id: 'legalRequirements',
    extras: { checkboxData: checkboxItemsLegalRequirements },
    alignment: 'column',
    rules: {
      required: 'Please select an option',
    },
  },
  {
    label: 'Operational Requirements',
    type: 'tableChecklist',
    id: 'operationalRequirements',
    extras: { checkboxData: checkboxItemsOperationalRequirements },
    alignment: 'column',
    rules: {
      required: 'Please select an option',
    },
  },
  {
    label: 'Checks',
    type: 'tableChecklist',
    id: 'checks',
    extras: { checkboxData: checkboxItemsChecks },
    alignment: 'column',
    rules: {
      required: 'Please select an option',
    },
  },
];

export const assessmentFindingsDa1PartItemsV11: Array<FormItemInterface> = [
  {
    label: '(A) Online Material / Location / Account',
    type: 'tableChecklist',
    id: 'onlineMaterial',
    extras: {
      checkboxData: checkboxItemsOnlineMaterial,
      tooltip: {
        content: 'onlineMaterial',
      },
    },
    alignment: 'column',
    rules: {
      minimum: 1,
    },
  },
  {
    label: '(B) Scam Checklist',
    type: 'tableChecklist',
    id: 'scamChecklist',
    extras: {
      checkboxData: checkboxItemsScamChecklist,
      tooltip: {
        content: 'scamChecklist',
      },
    },
    alignment: 'column',
    rules: {
      minimum: 1,
    },
  },
  {
    label: '(C) Evaluation',
    type: 'tableChecklist',
    id: 'evaluation',
    extras: {
      checkboxData: checkboxItemsEvaluation,
    },
    alignment: 'column',
  },
];

export const assessmentFindingsDa2PartItemsV11: Array<FormItemInterface> = [
  {
    label: 'Legal Requirements',
    type: 'tableChecklist',
    id: 'legalRequirements',
    extras: { checkboxData: checkboxItemsLegalRequirements },
    alignment: 'column',
    rules: {
      required: 'Please select an option',
    },
  },
  {
    label: 'Operational Requirements',
    type: 'tableChecklist',
    id: 'operationalRequirements',
    extras: { checkboxData: checkboxItemsOperationalRequirements },
    alignment: 'column',
    rules: {
      required: 'Please select an option',
    },
  },
  {
    label: 'Checks',
    type: 'tableChecklist',
    id: 'checks',
    extras: { checkboxData: checkboxItemsChecks },
    alignment: 'column',
    rules: {
      required: 'Please select an option',
    },
  },
];

export const getAssessmentFindingsSubstitutionOrderItems = (
  handleReferenceNoChange?: any,
  isReferenceNoValid?: boolean | null,
  isValidating?: boolean
): Array<FormItemInterface> => {
  return [
    {
      label: 'For Substitution Order',
      type: 'tableChecklist',
      id: 'onlineMaterial',
      extras: {
        checkboxData: getCheckboxItemsSubstituteOrder(handleReferenceNoChange, isReferenceNoValid, isValidating),
      },
      alignment: 'column',
    },
  ];
};

export const getConfig = (version: string, handleReferenceNoChange?: any, isReferenceNoValid?: boolean | null, isValidating?: boolean) => {
  switch (version) {
    case '1.00':
      return getConfigV1();
    case '1.1':
      return getConfigV11(handleReferenceNoChange, isReferenceNoValid, isValidating);
    default:
      return getConfigV11(handleReferenceNoChange, isReferenceNoValid, isValidating);
  }
};

const getConfigV1 = () => {
  return {
    assessmentFindingsInitialItems,
    assessmentFindingsDa1PartItems: assessmentFindingsDa1PartItemsV100,
    assessmentFindingsDa2PartItems: assessmentFindingsDa2PartItemsV100,
    assessmentFindingsSubstitutionOrderItems: [],
  };
};

const getConfigV11 = ( handleReferenceNoChange?: any, isReferenceNoValid?: boolean | null, isValidating?: boolean) => {
  return {
    assessmentFindingsInitialItems: [],
    assessmentFindingsDa1PartItems: assessmentFindingsDa1PartItemsV11,
    assessmentFindingsDa2PartItems: assessmentFindingsDa2PartItemsV11,
    assessmentFindingsSubstitutionOrderItems: getAssessmentFindingsSubstitutionOrderItems(handleReferenceNoChange, isReferenceNoValid, isValidating),
  };
};
