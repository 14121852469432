// src/Timeline.tsx

import { Timeline } from 'antd';
import ModalLayout from '@components/layouts/ModalLayout';
import { Modal } from '@mui/material';
import styled from 'styled-components';
import { ReactComponent as ActivityLogAssign } from '@assets/icons/activity_log_assign.svg';
import { ReactComponent as ActivityLogEdit } from '@assets/icons/activity_log_edit.svg';
import { ReactComponent as ActivityLogGeneratePO } from '@assets/icons/activity_log_generate_po.svg';
import { ReactComponent as ActivityLogOutcomeReset } from '@assets/icons/activity_log_outcome_reset.svg';
import { ReactComponent as ActivityLogDirectionsQueue } from '@assets/icons/activity_log_directions_queue.svg';
import { useGetActivtiyLogQuery } from '@api/activityLogSlice';
import { convertUnixToSingaporeTime } from '@utils/utils';
import { useEffect, useState } from 'react';
import { generalInformationItems, monikerDetailsItems } from '../../MonikerFormConfig';
import { CommonButton } from '@components/common/CommonButton';
import AssessmentFindingsModal from './AssessmentFindingsModal';
import { Link } from 'react-router-dom';
const { Item } = Timeline;

const CustomTimeline = styled(Timeline)`
  .ant-timeline-item-head {
  }

  .ant-timeline-item-content {
    position: relative;
    left: 10px;
    padding: 4px;
  }

  .timeline-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .timeline-text {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 500;
  }

  .timeline-datetime {
    font-size: 14px;
    color: #999;
  }
`;

const ActivityLog = ({
  isOpen,
  handleClose,
  id,
}: {
  isOpen: boolean;
  handleClose: any;
  id: string;
}) => {
  const { data: activityLogs, refetch: refetchActivityLogs } = useGetActivtiyLogQuery(
    {
      id: id,
    },
    { refetchOnMountOrArgChange: true },
  );

  const [activityLogItems, setActivityLogItems] = useState<any[]>([]);
  const [showAssessmentFindingsModal, setShowAssessmentFindingsModal] = useState(false);
  const [assessmentFindingsData, setAssessmentFindingsData] = useState({});

  const getUpdateDetails = (details: any) => {
    // Create an array of components
    const components = Object.entries(details).map(([key, value]) => getMonikerItem(key, value));

    // Render the array of components
    return <>{components}</>;
  };

  const getMonikerItem = (key: string, value: any) => {
    let config = monikerDetailsItems.concat(generalInformationItems);
    let item = config.find((item) => {
      return item.id === key;
    });
    if (item) {
      return (
        <p>
          <b>{item.label}</b> to{' '}
          <b>{item.type === 'labelEditDateTime' ? convertUnixToSingaporeTime(value) : value}</b>
        </p>
      );
    }
  };

  const getPOUpdateDetails = (details: any) => {
    // Create an array of components
    const components = Object.entries(details).map(([key, value]) => getPOItem(key, value));

    // Render the array of components
    return <>{components}</>;
  };

  const getPOItem = (key: string, value: any) => {
    let labelMapping = [
      {
        id: 'status',
        label: 'Status',
      },
    ];

    let item = labelMapping.find((item) => {
      return item.id === key;
    });
    if (item) {
      return (
        <p>
          <b>{item.label}</b> to <b>{value}</b>
        </p>
      );
    }
  };

  useEffect(() => {
    let tempItems: any = [];
    if (activityLogs && activityLogs.activityLogs) {
      const sortedLogs = [...activityLogs.activityLogs].sort((a, b) => b.createdAt - a.createdAt);

      sortedLogs.forEach((log: any) => {
        let tempItem: any = {};
        switch (log.action) {
          case 'sendToProductionOrderQueue':
            tempItem.dot = (
              <div className="w-8 h-8 rounded-full bg-blue-400 text-white flex justify-center items-center font-bold">
                <ActivityLogDirectionsQueue />
              </div>
            );
            tempItem.children = (
              <div className="timeline-content">
                <div className="timeline-text">
                  <b>{log.userName}</b> sent moniker to Directions Queue
                </div>
                <div className="timeline-datetime">{convertUnixToSingaporeTime(log.createdAt)}</div>
              </div>
            );

            break;
          case 'createProductionOrder':
          case 'createScamEnablerProductionOrders':
            tempItem.dot = (
              <div className="w-8 h-8 rounded-full bg-blue-400 text-white flex justify-center items-center font-bold">
                <ActivityLogGeneratePO />
              </div>
            );
            tempItem.children = (
              <div className="timeline-content">
                <div className="timeline-text">
                  <b>{log.userName}</b> generated a{' '}
                  <b>{`Production Order (PO#${log.details.directionReferenceNo || log.details.productionOrderId})`}</b>
                </div>
                <div className="timeline-datetime">{convertUnixToSingaporeTime(log.createdAt)}</div>
              </div>
            );
            break;
          // case 'updateScamEnablerOutcome':
          //   tempLog.icon = <ActivityLogOutcomeReset />;
          //   break;
          // case 'updateReportStatus':
          //   tempLog.icon = <ActivityLogOutcomeReset />;
          //   break;
          case 'updateReportAssignment':
          case 'updateScamEnablerAssignment':
            tempItem.dot = (
              <div className="w-8 h-8 rounded-full bg-blue-400 text-white flex justify-center items-center font-bold">
                <ActivityLogAssign />
              </div>
            );
            tempItem.children = (
              <div className="timeline-content">
                <div className="timeline-text">
                  <b>{log.userName}</b> assigned <b>{log.targetUserName}</b> as the{' '}
                  {log.details === -'da1'
                    ? 'Decision Authority 1 (DA1)'
                    : log.details === -'da2'
                      ? 'Decision Authority 2 (DA2)'
                      : log.details}
                </div>
                <div className="timeline-datetime">{convertUnixToSingaporeTime(log.createdAt)}</div>
              </div>
            );
            break;
          case 'updateScamEnablerOutcome':
            let outcome = '';
            if (typeof log.details === 'string') {
              outcome = log.details;
            } else {
              outcome = log.details.outcome;
            }
            tempItem.dot = (
              <div className="w-8 h-8 rounded-full bg-blue-400 text-white flex justify-center items-center font-bold">
                <ActivityLogEdit />
              </div>
            );
            tempItem.children = (
              <div className="timeline-content">
                <div className="timeline-text">
                  <b>{log.userName}</b> updated outcome to <b>{outcome}</b>
                </div>
                <div className="timeline-datetime">{convertUnixToSingaporeTime(log.createdAt)}</div>
              </div>
            );
            break;
          case 'updateProductionOrder':
            tempItem.dot = (
              <div className="w-8 h-8 rounded-full bg-blue-400 text-white flex justify-center items-center font-bold">
                <ActivityLogEdit />
              </div>
            );
            tempItem.children = (
              <div className="timeline-content">
                <div className="timeline-text">
                  <b>{log.userName}</b> edited Production Order with the following details:{' '}
                  {getPOUpdateDetails(log.details)}
                </div>
                <div className="timeline-datetime">{convertUnixToSingaporeTime(log.createdAt)}</div>
              </div>
            );
            break;
          case 'updateScamEnablerUserId':
          case 'updateScamEnabler':
            tempItem.dot = (
              <div className="w-8 h-8 rounded-full bg-blue-400 text-white flex justify-center items-center font-bold">
                <ActivityLogEdit />
              </div>
            );
            tempItem.children = (
              <div className="timeline-content">
                <div className="timeline-text">
                  <b>{log.userName}</b> edited the following details:{' '}
                  {getUpdateDetails(log.details)}
                </div>
                <div className="timeline-datetime">{convertUnixToSingaporeTime(log.createdAt)}</div>
              </div>
            );
            break;

          case 'updateScamEnablerAttachment':
            tempItem.dot = (
              <div className="w-8 h-8 rounded-full bg-blue-400 text-white flex justify-center items-center font-bold">
                <ActivityLogEdit />
              </div>
            );
            tempItem.children = (
              <div className="timeline-content">
                <div className="timeline-text">
                  <b>{log.userName}</b> added screenshots{' '}
                  <b>
                    {log?.details?.attachments?.length > 0 &&
                      log.details.attachments.map((screenshot: string) => {
                        return screenshot;
                      })}
                    {log?.details &&
                      !log?.details?.attachments &&
                      log.details.map((screenshot: string) => {
                        return screenshot;
                      })}
                  </b>
                </div>
                <div className="timeline-datetime">{convertUnixToSingaporeTime(log.createdAt)}</div>
              </div>
            );
            break;

          case 'deleteScamEnablerAttachment':
            tempItem.dot = (
              <div className="w-8 h-8 rounded-full bg-blue-400 text-white flex justify-center items-center font-bold">
                <ActivityLogEdit />
              </div>
            );
            tempItem.children = (
              <div className="timeline-content">
                <div className="timeline-text">
                  <b>{log.userName}</b> deleted screenshots{' '}
                  <b>
                    {log?.details?.attachments?.length > 0 &&
                      log.details.attachments.map((screenshot: string) => {
                        return screenshot;
                      })}
                    {log?.details &&
                      !log?.details?.attachments &&
                      log.details.map((screenshot: string) => {
                        return screenshot;
                      })}
                  </b>
                </div>
                <div className="timeline-datetime">{convertUnixToSingaporeTime(log.createdAt)}</div>
              </div>
            );
            break;
          case 'createReport':
          case 'createScamEnabler':
            tempItem.dot = (
              <div className="w-8 h-8 rounded-full bg-blue-400 text-white flex justify-center items-center font-bold">
                <ActivityLogEdit />
              </div>
            );
            tempItem.children = (
              <div className="timeline-content">
                <div className="timeline-text">
                  <b>{log.userName}</b> created ScamEnabler
                </div>
                <div className="timeline-datetime">{convertUnixToSingaporeTime(log.createdAt)}</div>
              </div>
            );
            break;

          case 'createScamEnablerSubmission':
            tempItem.dot = (
              <div className="w-8 h-8 rounded-full bg-blue-400 text-white flex justify-center items-center font-bold">
                <ActivityLogEdit />
              </div>
            );
            tempItem.children = (
              <div className="timeline-content">
                <div className="timeline-text">
                  <b>{log.userName}</b> created ScamEnabler Submission under{' '}
                  <Link
                    to={`/admin/reports/${log.reportId}`}
                    className="underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {log.snapshot.reportNumber}
                  </Link>
                </div>
                <div className="timeline-datetime">{convertUnixToSingaporeTime(log.createdAt)}</div>
              </div>
            );
            break;

          case 'updateReportAssessmentFindings':
          case 'updateScamEnablerAssessmentFindings':
            tempItem.dot = (
              <div className="w-8 h-8 rounded-full bg-blue-400 text-white flex justify-center items-center font-bold">
                <ActivityLogEdit />
              </div>
            );
            tempItem.children = (
              <div className="timeline-content">
                <div className="timeline-text">
                  <b>{log.userName}</b> edited the assessment findings.
                  <CommonButton
                    title="View Details"
                    variant="text-blue"
                    type="button"
                    onClick={() => {
                      setAssessmentFindingsData({
                        details: log.details,
                        user: log.userName,
                        modifiedAt: convertUnixToSingaporeTime(log.createdAt),
                      });
                      setShowAssessmentFindingsModal(true);
                    }}
                  />
                </div>
                <div className="timeline-datetime">{convertUnixToSingaporeTime(log.createdAt)}</div>
              </div>
            );
            break;
          case 'resetScamEnabler':
          case 'resetScamEnablerOutcome':
            tempItem.dot = (
              <div className="w-8 h-8 rounded-full bg-red text-white flex justify-center items-center font-bold">
                <ActivityLogOutcomeReset />
              </div>
            );
            tempItem.children = (
              <div className="timeline-content">
                <div className="timeline-text">
                  <b>{log.userName}</b> reset outcome to <b>{log.details.outcome}</b>
                </div>
                <div className="timeline-datetime">{convertUnixToSingaporeTime(log.createdAt)}</div>
              </div>
            );
            break;
          default:
            break;
        }
        tempItems.push(tempItem);
      });
    }
    setActivityLogItems(tempItems);
  }, [activityLogs]);

  return (
    <>
      <Modal open={isOpen} onClose={handleClose}>
        <ModalLayout
          title={'Activity Log'}
          handleCloseModal={() => {
            handleClose();
          }}
          alignment="right"
          size="full"
        >
          {activityLogItems && activityLogItems.length > 0 && (
            <CustomTimeline items={activityLogItems} />
          )}
        </ModalLayout>
      </Modal>
      <AssessmentFindingsModal
        showModal={showAssessmentFindingsModal}
        handleCloseModal={() => setShowAssessmentFindingsModal(false)}
        data={assessmentFindingsData}
      />
    </>
  );
};

export default ActivityLog;
