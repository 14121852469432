import { useMonikersDetailedPage } from './useMonikersDetailedPage';
import BreadcrumbsAndButtons from './BreadcrumbsAndButtons';
import DiarySection from '../DiarySection';
import { ScamEnablersSection } from './ScamEnablersSection';
import { CircularProgress } from '@mui/material';
import ReportOverview from './ReportOverview';
import AccessControl from '@components/common/AccessControl';

export function ReportsDetailedPage() {
  const {
    params,
    poQueueModalOpen,
    handleOpenQueuePOModal,
    handleCloseQueuePOModal,
    poModalOpen,
    handleOpenPOModal,
    handleClosePOModal,
    currentRole,
    refetch,
    data,
    currentUser,
  } = useMonikersDetailedPage();
  
  return (
    <>
      <AccessControl
        type="page"
        allowedPermissions={[
          'reports.scamenablers.view',
          'reports.banks.view',
          'reports.paynows.view',
        ]}
      >
        <BreadcrumbsAndButtons
          data={data}
          params={params}
          refetch={refetch}
          currentRole={currentRole}
          handleOpenQueuePOModal={handleOpenQueuePOModal}
          handleCloseQueuePOModal={handleCloseQueuePOModal}
          poQueueModalOpen={poQueueModalOpen}
          handleOpenPOModal={handleOpenPOModal}
          handleClosePOModal={handleClosePOModal}
          poModalOpen={poModalOpen}
        />

        <div className="box-border h-full flex overflow-hidden rounded-lg bg-white p-4">
          <section className="box-border w-1/3  flex flex-col rounded-lg bg-white mb-8">
            {/* Overview */}
            <div className="box-border flex-none bg-grey-100 rounded-lg mb-4 h-1/2 ">
              <ReportOverview data={data} refetch={refetch} />
            </div>

            {/* Diary */}
            <div className="box-border h-full flex flex-col bg-grey-100 justify-between align-middle rounded-lg p-4 overflow-auto">
              <DiarySection reportId={data?.report._id} data={data} />
            </div>
          </section>
          <section className="box-border w-2/3 flex flex-col bg-white rounded-lg pl-4 mb-8">
            <ScamEnablersSection
              data={data}
              reportId={params.id}
              currentUser={currentUser}
              refetch={refetch}
            />
          </section>
        </div>
      </AccessControl>
    </>
  );
}
export default ReportsDetailedPage;
