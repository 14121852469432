import { CommonButton } from '@components/common/CommonButton';
import FileUpload from '@components/common/FileUpload';
import ModalLayout from '@components/layouts/ModalLayout';
import { Badge, Modal, Tab, Tabs } from '@mui/material';
import { useEffect, useState } from 'react';
import { set, useForm, useWatch } from 'react-hook-form';
import csv from 'csvtojson';
import cloneDeep from 'lodash.clonedeep';
import { platform, scamType } from '@interface/constant';
import {
  cleanUpWhatsappNumber,
  convertUnixToSingaporeTime,
  delay,
  extractUserIdFromUrl,
} from '@utils/utils';
import { Table } from 'antd';
import { useDispatch } from 'react-redux';
import { showAlert } from '@store/alertSlice';
import {
  useCreateProactiveScamEnablersV2Mutation,
  useGetProactiveScamEnablersExistV2Mutation,
} from '@api/scamEnablerSlice';
import moment from 'moment';
import { ReactComponent as Warning } from '@assets/icons/warning_yellow.svg';
import JSZip, { file } from 'jszip';
import { render } from '@testing-library/react';
import { ProactiveMonikerOutcomeStatus } from '@components/common/StatusComponent';

const validColumns = [
  {
    title: 'No.',
    key: 'index',
    render: (text: string, record: any, index: number) => index + 1,
    width: '4%',
    textWrap: 'word-break',
  },
  {
    title: 'Profile Name',
    dataIndex: 'profilename',
    key: 'profilename',
    width: '5%',
  },
  {
    title: 'URL',
    dataIndex: 'url',
    key: 'url',
    width: '5%',
  },
  {
    title: 'Moniker Id',
    dataIndex: 'scamEnablerUserId',
    key: 'scamEnablerUserId',
    width: '5%',
  },
  {
    title: 'Platform',
    dataIndex: 'platform',
    key: 'platform',
    width: '5%',
  },
  {
    title: 'Profile Type',
    dataIndex: 'profileType',
    key: 'profileType',
    width: '5%',
  },
  {
    title: 'No of Ads',
    dataIndex: 'numberOfAds',
    key: 'numberOfAds',
    width: '5%',
  },
  {
    title: 'Scam Type',
    dataIndex: 'scamType',
    key: 'scamType',
    width: '5%',
  },
  {
    title: 'Submission Date',
    dataIndex: 'submissionDate',
    key: 'submissionDate',
    width: '8%',
    render: (text: string, record: any, index: number) =>
      convertUnixToSingaporeTime(record.submittedAt),
  },
  // {
  //   title: 'Submission Time',
  //   dataIndex: 'submissionTime',
  //   key: 'submissionTime',
  //   width: '10%',
  // },
  {
    title: 'Reference Number',
    dataIndex: 'referenceNo',
    key: 'referenceNo',
    width: '5%',
  },
  {
    title: 'Remarks',
    dataIndex: 'remarks',
    key: 'remarks',
    width: '5%',
  },
  {
    title: 'Facebook Page Created Date',
    dataIndex: 'facebookCreatedAt',
    key: 'facebookCreatedAt',
    width: '8%',
  },
  {
    title: "Scammer's Contact Number",
    dataIndex: 'scammerContactNumber',
    key: 'scammerContactNumber',
    width: '6%',
  },
  {
    title: 'Screenshot Match',
    width: '6%',
    render: (text: string, record: any, index: number) => {
      return record.attachments && record.attachments.length > 0 ? 'Yes' : '';
    },
  },
  {
    title: 'Attachment File Names',
    dataIndex: 'attachmentFileNames',
    key: 'attachmentFileNames',
    width: '6%',
  },
  {
    title: 'Tags',
    dataIndex: 'tags',
    key: 'tags',
    width: '5%',
  },
  {
    title: 'Verified Profile',
    dataIndex: 'verifiedProfile',
    key: 'verifiedProfile',
    width: '5%',
  },
  {
    title: 'Redirection URLs',
    dataIndex: 'redirectionUrls',
    key: 'redirectionUrls',
    width: '6%',
  },
];

const uploadingColumns = [
  {
    title: 'No.',
    key: 'index',
    render: (text: string, record: any, index: number) => index + 1,
    width: '7%',
    textWrap: 'word-break',
  },
  {
    title: 'Profile Name',
    dataIndex: 'profilename',
    key: 'profilename',
    width: '18%',
  },
  {
    title: 'URL',
    dataIndex: 'url',
    key: 'url',
    width: '20%',
  },
  {
    title: 'Moniker Id',
    dataIndex: 'scamEnablerUserId',
    key: 'scamEnablerUserId',
    width: '18%',
  },
  {
    title: 'Platform',
    dataIndex: 'platform',
    key: 'platform',
    width: '12%',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '25%',
    render: (text: string, record: any, index: number) => (
      <>
        {text === 'Verifying...' && <p className="text-grey-400 italic">{text}</p>}
        {(text.includes('error') || text.includes('Failed')) && <p className="text-red">{text}</p>}
        {!(text.includes('error') || text.includes('Failed')) && text !== 'Verifying...' && (
          <ProactiveMonikerOutcomeStatus outcome={text} />
        )}
      </>
    ),
  },
];
const invalidColumns = [
  {
    title: 'No.',
    key: 'index',
    render: (text: string, record: any, index: number) => index + 1,
    width: '4%',
  },

  {
    title: 'Profile Name',
    dataIndex: 'profilename',
    key: 'profilename',
    width: '6%',
  },
  {
    title: 'URL',
    dataIndex: 'url',
    key: 'url',
    width: '7%',
  },
  {
    title: 'Moniker Id',
    dataIndex: 'scamEnablerUserId',
    key: 'scamEnablerUserId',
    width: '5%',
  },
  {
    title: 'Platform',
    dataIndex: 'platform',
    key: 'platform',
    width: '5%',
  },
  {
    title: 'Profile Type',
    dataIndex: 'profileType',
    key: 'profileType',
    width: '5%',
  },
  {
    title: 'No of Ads',
    dataIndex: 'numberOfAds',
    key: 'numberOfAds',
    width: '5%',
  },
  {
    title: 'Scam Type',
    dataIndex: 'scamType',
    key: 'scamType',
    width: '5%',
  },
  {
    title: 'Submission Date',
    dataIndex: 'submittedAt',
    key: 'submittedAt',
    width: '6%',
    render: (text: string, record: any, index: number) =>
      convertUnixToSingaporeTime(record.submittedAt),
  },
  // {
  //   title: 'Submission Time',
  //   dataIndex: 'submissionTime',
  //   key: 'submissionTime',
  //   width: '7%',
  // },
  {
    title: 'Reference Number',
    dataIndex: 'referenceNo',
    key: 'referenceNo',
    width: '5%',
  },
  {
    title: 'Remarks',
    dataIndex: 'remarks',
    key: 'remarks',
    width: '5%',
  },
  {
    title: 'Facebook Page Created Date',
    dataIndex: 'facebookCreatedAt',
    key: 'facebookCreatedAt',
    width: '6%',
  },
  {
    title: "Scammer's Contact Number",
    dataIndex: 'scammerContactNumber',
    key: 'scammerContactNumber',
    width: '6%',
  },
  {
    title: 'Attachment File Names',
    dataIndex: 'attachmentFileNames',
    key: 'attachmentFileNames',
    width: '6%',
  },
  {
    title: 'Tags',
    dataIndex: 'tags',
    key: 'tags',
    width: '5%',
  },
  {
    title: 'Verified Profile',
    dataIndex: 'verifiedProfile',
    key: 'verifiedProfile',
    width: '5%',
  },
  {
    title: 'Redirection URLs',
    dataIndex: 'redirectionUrls',
    key: 'redirectionUrls',
    width: '7%',
  },
  {
    title: 'Reason',
    dataIndex: 'errorMessage',
    key: 'errorMessage',
    width: '7%',
    render: (text: string, record: any, index: number) => (
      <p className="text-red whitespace-pre-line">{text}</p>
    ),
  },
];
const CreateProactiveMonikerExcelModal = ({
  id,
  isOpen,
  onClose,
  callback,
}: {
  id: string;
  isOpen: boolean;
  onClose: () => void;
  callback?: () => void;
}) => {
  const formMethods = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [createProactiveScamEnabler] = useCreateProactiveScamEnablersV2Mutation();
  const [modalStage, setModalStage] = useState('uploadAttachments');
  const [validCsvData, setValidCsvData] = useState<any[]>([]); // Specify the type of csvData as an array of objects
  const [invalidCsvData, setInvalidCsvData] = useState<any[]>([]); // Specify the type of csvData as an array of objects
  const [tabValue, setTabValue] = useState('valid');
  const dispatch = useDispatch();
  const [checkUserId] = useGetProactiveScamEnablersExistV2Mutation();
  const [isFileVerifying, setIsFileVerifying] = useState(false);
  const [csvDataUploadList, setCsvDataUploadList] = useState<any[]>([]);

  const watchedValues = useWatch({ control: formMethods.control });

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (csvDataUploadList.length > 0) {
      let allSuccess = csvDataUploadList.every(
        (item) => item.status !== 'Verifying...' && !item.status.includes('Failed'),
      );
      if (allSuccess) {
        setIsLoading(false);
        if (callback) callback();
        dispatch(
          showAlert({
            message: 'Proactive Scam Enabler(s) added successfully',
            severity: 'success',
          }),
        );
      }
    }
  }, [csvDataUploadList]);
  const validatePlatform = (element: any) => {
    let allowedPlatform = platform;
    if (
      element?.platform &&
      allowedPlatform
        .map((platform) => platform.toLowerCase())
        .includes(element.platform.toLowerCase())
    ) {
      return allowedPlatform.find(
        (platform) => platform.toLowerCase() === element.platform.toLowerCase(),
      );
    }
    return null;
  };

  const validateScamType = (element: any) => {
    let allowedScamType = scamType;
    if (!element.scamType) return '';
    else if (
      element.scamType &&
      allowedScamType
        .map((scamType) => scamType.toLowerCase())
        .includes(element.scamType.toLowerCase())
    ) {
      return allowedScamType.find(
        (scamType) => scamType.toLowerCase() === element.scamType.toLowerCase(),
      );
    }
    return false;
  };

  const validateAndFormatFields = (element: any) => {
    const platform = validatePlatform(element);
    const scamType = validateScamType(element);

    if (platform === 'Facebook' && element.url) {
      element.scamEnablerUserId = extractUserIdFromUrl(element.url, 'Facebook').userId;
    }
    const scamEnablerUserId =
      platform === 'Whatsapp'
        ? cleanUpWhatsappNumber(element.scamEnablerUserId)
        : element.scamEnablerUserId;

    return { platform, scamEnablerUserId, scamType };
  };

  const setJSONData = async (jsonData: any[]) => {
    setIsFileVerifying(true);
    let tempValidData: any = [];
    let validData: any = [];
    let invalidData: any = [];
    console.log('jsonData', jsonData);
    jsonData.forEach((element) => {
      let errorMessage = '';

      if (!element.platform) {
        errorMessage += 'Missing platform\n\n';
      }
      const { platform, scamEnablerUserId, scamType } = validateAndFormatFields(element);
      if (element.numberOfAds) {
        element.numberOfAds = parseInt(element.numberOfAds);
      }
      if (!element.scamEnablerUserId && !scamEnablerUserId) {
        errorMessage += 'User ID is required\n\n';
      }
      if (!platform) {
        errorMessage += 'Invalid platform\n\n';
      }
      if (scamType === false) {
        errorMessage += 'Invalid scam type\n\n';
      }
      if (!element.profilename) {
        errorMessage += 'Missing Profile Name\n\n';
      }
      if (
        (!element.submissionDate && element.submissionTime) ||
        (element.submissionDate && !element.submissionTime)
      ) {
        errorMessage += 'Submission Date and Time are required\n\n';
      }
      if (element.submissionDate && element.submissionTime) {
        // const dateTimeString = `${element.submissionDate} ${formattedTime}`;
        // console.log('dataTimeString', dateTimeString);
        element.submittedAt = parseDateString(element.submissionDate, element.submissionTime);
        delete element.submissionTime;
        delete element.submissionDate;
        if (!element.submittedAt) {
          errorMessage +=
            'Invalid Submission DateTime Format, submission date to be in the format DD MMM YYYY or DD/MM/YYYY. Submission time to be in the format HHmm\n\n';
        }
      }

      if (element.profileType && element.profileType !== 'User' && element.profileType !== 'Page') {
        errorMessage += 'Invalid Profile Type\n\n';
      }
      if (errorMessage) {
        invalidData.push({ ...element, platform, scamType, scamEnablerUserId, errorMessage });
      } else {
        tempValidData.push({
          scamEnablerUserId,
          platform: platform,
        });
      }
    });
    let response = await checkUserId({
      proactiveScamEnablers: tempValidData,
    }).unwrap();
    response.result.forEach((item: any) => {
      let element = jsonData.find(
        (element) => element.scamEnablerUserId === item.scamEnablerUserId,
      );

      if (item.exists === false) {
        validData.push({
          ...element,
        });
      } else {
        invalidData.push({
          ...element,
          errorMessage: 'Unable to create, moniker Id already exists',
        });
      }
    });

    let uploadedAttachments = cloneDeep(formMethods.getValues(`${id}-attachments`));
    if (uploadedAttachments && uploadedAttachments[0]?.fileContent) {
      const zip = new JSZip();
      let fileContent = (uploadedAttachments[0].fileContent =
        uploadedAttachments[0].fileContent.split(',')[1]);
      const attachments = await zip.loadAsync(fileContent, { base64: true });
      const files: any = [];

      attachments.forEach((relativePath, file) => {
        files.push(
          file.async('base64').then((base64Content) => ({
            contentType: file.name.split('.').pop(),
            fileContent: base64Content,
            fileName: relativePath.split('/').pop(),
          })),
        );
      });

      const unzippedFiles = await Promise.all(files);

      // Match filenames with scamEnablerUserId in validData
      validData.forEach((item: any) => {
        item.attachments = unzippedFiles.filter((file) => {
          const filenameWithoutExtension = file.fileName.split('/').pop()?.split('.')[0];
          return filenameWithoutExtension === item.scamEnablerUserId;
        });
      });
    }

    setValidCsvData(validData);
    setInvalidCsvData(invalidData);
    setIsFileVerifying(false);
  };

  const parseDateString = (dateString: string, timeString: string) => {
    const formattedTime =
      timeString.length === 3
        ? timeString.slice(0, 1) + ':' + timeString.slice(2)
        : timeString.slice(0, 2) + ':' + timeString.slice(2);
    const dateTimeString = `${dateString} ${formattedTime}`;

    // Define the supported formats
    const formats = [
      'D MMM YYYY HH:mm',
      'DD MMM YYYY HH:mm',
      'DD/MM/YYYY HH:mm',
      'D/MM/YYYY HH:mm',
    ];

    // Parse the date string using moment with the supported formats
    const parsedDate = moment(dateTimeString, formats, true);
    // Check if the date is valid
    if (!parsedDate.isValid()) {
      return '';
    }

    return parsedDate.valueOf();
  };

  const showCsvContent = async () => {
    let uploadedFile = cloneDeep(formMethods.getValues(id));
    if (!uploadedFile[0]?.fileContent) {
      setValidCsvData([]);
      setInvalidCsvData([]);
      return;
    }

    uploadedFile[0].fileContent = uploadedFile[0].fileContent.split(',')[1];
    let csvData = Buffer.from(uploadedFile[0].fileContent, 'base64').toString('utf8');
    let jsonData = await csv().fromString(csvData);
    await setJSONData(jsonData);
  };
  const handleNext = async () => {
    if (modalStage === 'uploadAttachments') {
      setIsLoading(true);
      await showCsvContent();
      setIsLoading(false);
      setModalStage('evaluate');
      return;
    } else if (modalStage === 'evaluate') {
      setModalStage('uploadAPI');
      onUpload();
    } else if (modalStage === 'uploadAPI') {
    }
  };
  const handlePrevious = async () => {
    if (modalStage === 'evaluate') {
      setModalStage('uploadAttachments');
    }
  };
  const checkDisabled = () => {
    if (modalStage === 'uploadAttachments') {
      let uploadedFile = cloneDeep(watchedValues);
      return !uploadedFile ||
        Object.keys(uploadedFile).length === 0 ||
        !(uploadedFile.fileUpload && uploadedFile.fileUpload.length > 0)
        ? true
        : false;
    } else if (modalStage === 'evaluate') {
      return validCsvData.length <= 0 ? true : false;
    } else if (modalStage === 'uploadAPI') {
    }
    return true;
  };

  const onUpload = async () => {
    if (validCsvData.length <= 0) return;
    let createRequest: any = [];
    validCsvData.forEach((element) => {
      let tempElement = cloneDeep(element);
      // if (element.submissionDate && element.submissionTime) {
      //   const formattedTime =
      //     element.submissionTime.length === 3
      //       ? element.submissionTime.slice(0, 1) + ':' + element.submissionTime.slice(2)
      //       : element.submissionTime.slice(0, 2) + ':' + element.submissionTime.slice(2);
      //   // const dateTimeString = `${element.submissionDate} ${formattedTime}`;
      //   // console.log('dataTimeString', dateTimeString);
      //   tempElement.submittedAt = parseDateString(element.submissionDate, formattedTime);
      //   delete tempElement.submissionTime;
      //   delete tempElement.submissionDate;
      // }
      if (element.scammerContactNumber) {
        tempElement.scammerContactNumber = tempElement.scammerContactNumber.split(',');
      }
      // if (element.attachmentFileNames) {
      //   tempElement.attachmentFileNames = tempElement.attachmentFileNames.split(',');
      // }
      if (element.facebookCreatedAt) {
        tempElement.facebookCreatedAt = new Date(element.facebookCreatedAt).getTime();
      }
      if (!element.numberOfAds) {
        delete tempElement.numberOfAds;
      }
      if (element.attachmentFileNames) {
        tempElement.attachmentFileNames = element.attachmentFileNames.split(',');
      }
      if (element.tags) {
        tempElement.tags = element.tags.split(',');
      }
      if (element.verifiedProfile) {
        tempElement.verifiedProfile = element.verifiedProfile === 'Yes';
      } else {
        tempElement.verifiedProfile = false;
      }
      if (element.redirectionUrls) {
        let redirectionUrls = element.redirectionUrls.split(',');
        tempElement.redirectionUrls = [];
        for (let redirectionUrl of redirectionUrls) {
          let tempRedirectionurl = {
            url: redirectionUrl,
            attachments: [],
          };
          tempElement.redirectionUrls.push(tempRedirectionurl);
        }
      }
      createRequest.push(tempElement);
    });
    let status: any[] = [];
    status = createRequest.map((element: any) => {
      return {
        ...element,
        status: 'Verifying...',
      };
    });
    setCsvDataUploadList(status);
    setIsLoading(true);

    for (let i = 0; i < createRequest.length; i++) {
      const request = createRequest[i];

      let response: any = await createProactiveScamEnabler([request]).then((response: any) => {
        if (!response.error) {
          status[i].status = 'Under Review';
        } else {
          status[i].status = `Failed. ${response.error.data}`;
        }

        setCsvDataUploadList([...status]);
      });

      await delay(100); // Wait for 100ms before submitting the next request
    }
    if (callback) callback();
    // onClose();

    setIsLoading(false);
  };

  const handleDownloadTemplate = () => {
    const link = document.createElement('a');
    link.href = '/assets/template/proactive_csv_upload_template.csv';
    link.download = 'SATIS+ Proactive Moniker CSV Upload Template.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalLayout
        title={modalStage === 'uploadAPI' ? 'Status Verification' : 'Add Report - Upload List'}
        handleCloseModal={onClose}
        alignment="center"
        size="large"
        overflowClass="overflow-hidden"
      >
        <div className="flex flex-col  h-[60vh] justify-between">
          {modalStage === 'uploadAttachments' && (
            <>
              <div className="flex flex-row items-center p-4 bg-yellow-200 border border-yellow rounded-lg mb-5 -mt-8">
                <Warning />
                <p className="pl-4 text-xs">
                  Please ensure that the Screenshots file attached is for the monikers in the
                  Moniker List. The system will match and attach the Screenshots file to each of the
                  monikers in the Moniker List accordingly
                </p>
              </div>
            </>
          )}
          {modalStage === 'uploadAttachments' && (
            <form className="flex flex-row justify-between pb-10">
              <div className="w-[45%] h-[40vh]">
                <h3 className="font-bold pb-2">Screenshots</h3>
                <FileUpload
                  id={`${id}-attachments`}
                  control={formMethods.control}
                  setValue={formMethods.setValue}
                  getValues={formMethods.getValues}
                  onClose={onClose}
                  fileType={['application/zip', 'application/x-zip-compressed']}
                  maxFiles={1}
                  maxfileSizeMB={10}
                  // callback={showCsvContent}
                  isLoading={isFileVerifying}
                  allowPaste={false}
                />
              </div>
              <div className="w-[45%] h-[40vh]">
                <div className="flex flex-row justify-between">
                  <h3 className="font-bold pb-2">Moniker List</h3>
                  <div>
                    <CommonButton
                      className="pb-3"
                      title="Download CSV Template"
                      variant="text-blue"
                      onClick={handleDownloadTemplate}
                      type="button"
                    />
                  </div>
                </div>
                <FileUpload
                  id={id}
                  control={formMethods.control}
                  setValue={formMethods.setValue}
                  getValues={formMethods.getValues}
                  onClose={onClose}
                  fileType={['text/csv']}
                  maxFiles={1}
                  // callback={showCsvContent}
                  isLoading={isFileVerifying}
                  allowPaste={false}
                />
              </div>
            </form>
          )}
          {modalStage === 'evaluate' &&
            ((validCsvData && validCsvData.length > 0) ||
              (invalidCsvData && invalidCsvData.length > 0)) && (
              <>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  TabIndicatorProps={{ style: { backgroundColor: '#255662' } }}
                >
                  <Tab
                    label={
                      <div className="flex items-center gap-4">
                        <p
                          className={`font-bold ${tabValue === 'valid' ? 'text-blue-500' : 'text-blue-400'}`}
                          style={{ textTransform: 'none' }}
                        >
                          Successful
                        </p>
                        <Badge color="success" badgeContent={validCsvData.length || 0} max={999} />
                      </div>
                    }
                    value="valid"
                  />
                  <Tab
                    label={
                      <div className="flex items-center gap-4">
                        <p
                          className={`font-bold ${tabValue === 'invalid' ? 'text-blue-500' : 'text-blue-400'}`}
                          style={{ textTransform: 'none' }}
                        >
                          Error
                        </p>
                        <Badge color="error" badgeContent={invalidCsvData.length || 0} max={999} />
                      </div>
                    }
                    value="invalid"
                  />
                </Tabs>
                {tabValue === 'valid' && (
                  <div className="h-[40vh] overflow-y-auto">
                    <Table
                      rowKey="scamEnablerUserId"
                      dataSource={cloneDeep(validCsvData)}
                      columns={validColumns}
                      pagination={false}
                      scroll={{ x: 'max-content' }} // Enable horizontal scrolling
                      sticky
                    />
                  </div>
                )}
                {tabValue === 'invalid' && (
                  <div className="h-[40vh] overflow-y-auto">
                    <Table
                      rowKey="scamEnablerUserId"
                      dataSource={cloneDeep(invalidCsvData)}
                      columns={invalidColumns}
                      pagination={false}
                      scroll={{ x: 'max-content' }} // Enable horizontal scrolling
                      sticky
                    />
                  </div>
                )}
              </>
            )}
          {modalStage === 'uploadAPI' && (
            <div className="h-[40vh] overflow-y-auto">
              <Table
                rowKey="scamEnablerUserId"
                dataSource={cloneDeep(csvDataUploadList)}
                columns={uploadingColumns}
                pagination={false}
                scroll={{ x: 'max-content' }} // Enable horizontal scrolling
                sticky
              />
            </div>
          )}
          <div className="flex justify-end gap-4 pt-4">
            {(modalStage === 'evaluate' || modalStage === 'uploadAttachments') && (
              <CommonButton isDisabled={false} variant="text" title={`Cancel`} onClick={onClose} />
            )}
            {modalStage === 'evaluate' && (
              <CommonButton
                isDisabled={false}
                variant="secondary"
                title={`Previous`}
                onClick={handlePrevious}
              />
            )}
            {modalStage !== 'uploadAPI' && (
              <CommonButton
                isLoading={isLoading}
                variant="primary"
                title={modalStage === 'evaluate' ? 'Upload Sucessful Rows' : 'Continue'}
                onClick={handleNext}
                isDisabled={checkDisabled()}
              />
            )}
          </div>
        </div>
      </ModalLayout>
    </Modal>
  );
};

export default CreateProactiveMonikerExcelModal;
