import DynamicForm from '@components/common/DynamicForm';
import RadioInput from '@components/common/Radio';
import { ErrorOutline } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { getConfig } from './AssessmentFindingsConfig';
import { useState } from 'react';
import { useUpdateAssessmentFindingsV2Mutation } from '@api/scamEnablerSlice';
import { useDispatch } from 'react-redux';
import { CommonButton } from '@components/common/CommonButton';
import { showAlert } from '@store/alertSlice';
import ReassignmentConfirmationModal from '@pages/portfolios/monikers/modals/ReassignmentConfirmationModal';
import { convertValues } from '@utils/utils';
import { CHECKLIST_VERSION } from '../../../../../constants';
import _ from 'lodash';

const preSubmissionChannelGuidelinesItems = {
  label: 'Do you approve the channel submission to be via Community Guideline?',
  type: 'boolean',
  id: 'community_Q1',
  alignment: 'column',
  extras: {
    defaultValue: 'true',
  },
  rules: {
    required: 'Please select an option',
  },
};
const AssessmentFindingsMonikerPresubmitChecklist2 = ({
  formMethods,
  data,
  showOcha,
  currentRole,
  currentUser,
  callback,
  isEditing,
  setIsEditing,
  isLoading,
  showEdit,
  productionOrder,
  showRequiredFieldsMessage,
  onReturn,
}: {
  formMethods: any;
  data: any;
  showOcha: boolean;
  currentRole: string;
  currentUser: any;
  callback: any;
  isEditing: boolean;
  setIsEditing: any;
  isDA2Assigned: boolean;
  isLoading: boolean;
  showEdit: boolean;
  productionOrder: any;
  showRequiredFieldsMessage?: boolean;
  onReturn: () => void;
}) => {
  const isSubmitDisabled = !formMethods.formState.isValid;
  const [version, setVersion] = useState(
    (productionOrder?.scamEnablers &&
      productionOrder?.scamEnablers[0]?.findings?.monikerPreSubmitChecklist1?.version) ||
      data?.scamEnabler?.findings?.monikerPreSubmitChecklist1?.version ||
      CHECKLIST_VERSION,
  );

  const dispatch = useDispatch();

  return (
    <Stack sx={{ width: '100%' }} spacing={1}>
      <div className="flex flex-row justify-between">
        <h1 className="font-semibold text-sm text-blue-500">Assessment by DA2</h1>
        <p className="italic text-grey-500">Checklist V{version}</p>
      </div>
      <form className="overflow-auto h-[55vh]">
        {showOcha ? (
          <DynamicForm
            formFields={getConfig(version)?.assessmentFindingsDa2PartItems}
            formMethods={formMethods}
            isEditable={isEditing}
            isLoading={isLoading}
          ></DynamicForm>
        ) : (
          <>
            <label className="w-1/2 font-bold mr-4">
              {preSubmissionChannelGuidelinesItems.label}
            </label>
            <RadioInput
              control={formMethods.control}
              setValue={formMethods.setValue}
              id="checklist2_community_Q1"
              rules={preSubmissionChannelGuidelinesItems.rules}
              formState={formMethods.formState}
              isEditable={isEditing}
            />
          </>
        )}
        <div>
          {isSubmitDisabled && showRequiredFieldsMessage && (
            <div className="flex">
              <ErrorOutline className="mr-2" />
              <span className="italic text-sm">
                All required fields need to be filled before proceeding.
              </span>
            </div>
          )}
        </div>
      </form>
    </Stack>
  );
};

export default AssessmentFindingsMonikerPresubmitChecklist2;
